
:root {
  --yellow: linear-gradient(180deg, #F8D49A -146.42%, #FAD79D -46.42%);
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --purple: linear-gradient(180deg, #BB67FF 0%, #C484F3 100%);
  --pink: #FF919D;
  --glass: rgba(255, 255, 255, 0.54);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --activeItem: #f799a354;
  --clr-gray100: #f9fbff;

}

.homeappglass::-webkit-scrollbar {
  display: none;
}


.homeapp {
  color: var(--black);
  background: linear-gradient(#fce7f3, #e9d5ff);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
}

.homeappglass {
  height: 100%;
  width: 100%;
  /* background: url("../Assets/plain.jpg") no-repeat center center; */
  background: #F5F5F7;
  /* border-radius: 2rem; */
  overflow-y: scroll;
  overflow-x: hidden;
}

.homecontainer {
  display: flex;
  /* margin-top: 10px; */
  gap: 16px;
  grid-template-columns: 11rem auto 20rem;
  /* justify-content: center; */
  /* align-items: center; */
}

.link {
  text-decoration: none;
  color: inherit;
}

.headername{
  font-family: 'Mukta';
font-style: normal;
font-weight: 600;
font-size: 40px;
line-height: 66px;
text-align: center;
letter-spacing: -0.02em;
margin-top: 50px;
color: #161616;
}


/* sidebar */
.sidebar {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  position: relative;
  /* position: absolute; */
/* width: 326px; */
height:100vh;
/* left: 0px;
top: 0px; */
  /* padding-top: 1rem; */
  padding-right: 1rem;
  transition: all 300ms ease;
  overflow-y: scroll;
  /* border-top-right-radius: 2rem; */

}

/* logo */
.bars {
  display: none;
}


/* menu */
.menu {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  min-height: 83vh;
  margin-top: 0.5rem;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 2.5rem;
  margin-left: 2rem;
  position: relative;
  transition: all 300ms ease;
  border-radius:  0 0.7rem 0.7rem 0;
  font-size: 16px;
}

.menuItem:hover {
  cursor: pointer;
}



.active {
  background: #102149;
  margin-left: 0;
  padding-right: 15px;
  color: #FFFFFF;
  width: 100%;
}

.active::before {
  content: "";
  width: 8px;
  height: 100%;
  margin-right: calc(1rem - 8px);
}

.first {
  overflow-x: scroll;
}
















@media screen and (max-width: 1200px) {
  .homecontainer {
    grid-template-columns: 10% 50% auto;
    /* overflow-y: scroll; */
  }

  .homeappglass {
    grid-template-columns: 10% 50% auto;
    /* overflow-y: scroll; */
  }

  .menuItem>span {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .homecontainer {
    grid-template-columns: 1fr;
  }

  .homeappglass {
    grid-template-columns: 1fr;
  }

  .sidebar {
    position: fixed;
    z-index: 9;
    background: #f3f4f6;
    width: 55%;
    padding-right: 1rem;
    height: 100%;
  }

  .menuItem>span {
    display: block;
  }

  .bars {
    display: flex;
    position: fixed;
    top: 1rem;
    left: 60%;
    background: #f3f4f6;
    padding: 10px;
    border-radius: 10px;
    z-index: 9;
  }

  .close {
    left: -60%;
  }

  .expands {
    left: 0;
  }

  .not-expands {
    left: -60%;
  }

}