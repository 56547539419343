@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url('https://fonts.googleapis.com/css2?family=Mouse+Memoirs&family=Roboto:wght@300&display=swap');

.App {
  /* width: 100vw;
  height: 100vh; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  place-items: center;
  color: black;
  background-color: #f89571;
  min-height: 100vh;
  padding: 1rem 0.5rem;
  position: relative;
}
.App2{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.App3{
  color: #2B3B47;
  background: #F5F5F7;
  font-family: 'Mukta';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  min-height: 100vh;
  position: relative;
}
.App4{
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
  color: black;
  background: #F5F5F7;
  min-height: 100vh;
  padding: 1rem 0.5rem;
  position: relative;
  margin: auto;
}
.vipslogo{
color: black;
position: absolute;
top: 1rem;
left: 2rem;
z-index: 1;
}
a{
  text-decoration: none;
}

::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0%;
  padding: 0%;
  /* font-family: 'Roboto', sans-serif; */
}
.footers{
  text-align: center;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #1e40af;
  color: white;
  border: 1px solid #BFBFBF;
  box-shadow: 5px -10px 8px 1px rgba(0,0,0,0.07);
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-container {
  width: 400px;
  height: 400px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
}

.form-container .header {
  flex: 20%;
  display: grid;
  place-items: center;
}

.form-container .body {
  flex: 60%;
}

.form-container .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
}

.form-container .footer button {
  border-radius: 7px;
  width: 100px;
  height: 40px;
  background-color: rgb(255, 0, 140);
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 1px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  margin: 5px;
}

.sign-up-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
}

.sign-up-container input {
  margin: 5px;
  width: 190px;
  height: 40px;
  padding-left: 5px;
  font-size: 20px;
}

.personal-info-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.personal-info-container input {
  margin: 5px;
  width: 200px;
  height: 40px;
  padding-left: 5px;
  font-size: 20px;
}

/* PROGRESS BAR */
.progressbar {
  width: 400px;
  height: 10px;
  background-color: white;
  margin-bottom: 50px;
}

.progressbar div {
  width: 33.3%;
  height: 100%;
  background-color: rgb(98, 0, 255);
}

.other-info-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.other-info-container input {
  margin: 5px;
  width: 200px;
  height: 40px;
  padding-left: 5px;
  font-size: 20px;
}



input:focus {
  outline: none;
}
.setup{
  font-family: 'Mukta';
font-style: normal;
font-weight: 600;
font-size: 36px;
text-align: center;
letter-spacing: -0.02em;
margin-top: 1.5rem;
  color: #2B3B47;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  gap: 2rem;
}
.gap{
  display: flex;
  gap: 10px;
  align-items: center;
}

.btn {
  margin-left: 88%;
  margin-top: 1rem;
}

.changePass {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 15px;
  color: #fff6f6;
  background: #d81a10;
  padding: 5px 16px;
  text-align: center;
}
.clickhere{
  text-decoration: none;
  color: rgb(245, 244, 255) ;
  background-color: rgb(141, 11, 11);
}

.text-center {
  text-align: center;
}



input,
button,
textarea {
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  padding: 0.25rem;
}

/* label{
  width: 120px;
} */

.button{
  width: 120px;
  background-color: #1e40af;
  color: white;
} 
.button1{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 70px;
  background: #4285f4;
  line-height: 70px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 5px 10px 3px rgba(0, 0, 0, .1);
  overflow: hidden;
}
.button1 #splash{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0px;
  height: 0px;
  background: rgba(255, 255, 255, .2);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  transition: .5s ease-out;
}

.my-btn {
  width: 5rem;
  margin: auto;
  margin-top: 1rem;
}

.searchbox {
  display: flex;
  /* gap: 10px; */
}
.searchbox2 {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
}
.searchbox3 {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
}

.checkin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60vw;
  height: 70vh;
}
.checkinform{
  margin-left: 30%;
}
.checkinwidth{
  width: 78%;
}

.checkInSearch {
  box-sizing: border-box;
  /* position: absolute; */
  /* left: 626px;
  top: 250px; */
  border: 1px solid white;
  background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px 0px 0px 10px;
border: none;
padding-left: 20px;
  /* font-weight: 600; */
}
.checkInSearch2 {
  box-sizing: border-box;
  border: 1px solid white;
  background: rgba(242, 243, 242, 0.7);
  border-radius: 10px 0px 0px 10px;
/* box-shadow: px 4px 4px rgba(0, 0, 0, 0.25); */
  width: 500px;
  height: 50px;
  padding-left: 30px;
}
.checkInSearch3 {
  box-sizing: border-box;
  border: 1px solid white;
  background: rgba(242, 243, 242, 0.7);
  border-radius: 10px;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  padding-left: 30px;
}
.checkInSearch3a {
  box-sizing: border-box;
  border: 1px solid gray;
  height: 80px;
  /* border: 1px solid white; */
  background: rgba(242, 243, 242, 0.7);
  border-radius: 10px;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  
}
.checkInSearch4 {
  box-sizing: border-box;
  border: 1px solid white;
  background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
border: none;
padding-left: 20px;
margin-top: 3rem;
height: 2rem;
}
.checkInSearch5 {
  box-sizing: border-box;
  border: 1px solid white;
  background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 4px;
border: none;
height: 1.4rem;
width: 2rem;
padding: 0 8px;
}
.checkInSearch6{
  box-sizing: border-box;
    font-size: 10px;
    border: 1px solid white;
    background: rgba(242, 243, 242, 0.7);
    border-radius: 10px 0px 0px 10px;
    width: 100px;
    height: 40px;
    padding-left: 5px;
}
.checkInSubmit {
  border-radius: 0px 15px 15px 0px;
  /* position: absolute; */
  /* background: #0687FE; */
  color: white;
  background: #102149;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
padding-right: 10px;
border: none;
cursor: pointer;
}
.checkInSubmit2 {
  border-radius: 0px 15px 15px 0px;
  color: white;
  background: #102149;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
padding-right: 10px;
width: 150px;
height: 50px;
border: none;
cursor: pointer;
align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
}
.checkInSubmit3 {
border-radius: 10px;
width: 100%;
height: 55px;
  /* position: absolute; */
  /* background: #0687FE; */
  color: white;
  background: #102149;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border: none;
cursor: pointer;
padding: 6px 2rem;
color: white;
}
.checkInSubmit4 {
  border-radius: 10px;
  height: 55px;
    color: white;
    background: #102149;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  cursor: pointer;
  padding: 6px 2rem;
  color: white;
  }
  .checkInSubmit6{
    border-radius: 0px 15px 15px 0px;
    color: white;
    background: #102149;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-right: 10px;
  width: 50px;
  height: 40px;
  font-size: 12px;
  border: none;
  cursor: pointer;
  align-items: center;
      display: flex;
      justify-content: center;
      gap: 10px;
  }
.addcustomerheader{
  font-family: 'Mukta';
font-style: normal;
font-weight: 600;
font-size: 36px;
line-height: 1px;
text-align: center;
letter-spacing: -0.02em;
color: #2B3B47;
}
.addcustomerheader2{
  font-family: 'Mukta';
font-style: normal;
font-weight: 600;
font-size: 25px;
line-height: 30px;
letter-spacing: -0.02em;
color: #2B3B47;
}

.formboxcontainer{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1rem;
}

.headingalign{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.formbox {
  box-sizing: border-box;
  /* position: absolute; */
  width: 600px;
  height: 400px;
  /* left: 625px;
  top: 262px; */
  margin: auto;
  margin-bottom: 20%;
  background: #89C8C4;
  border: 1px solid #D05757;
}
.formbox2 {
  box-sizing: border-box;
  width: 500px;
  height: 320px;
  margin: auto;
  margin-bottom: 25%;
  background: #89C8C4;
  border: 1px solid #D05757;
}
.formbox3{
  width: 446px;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #FFFFFF;
border-radius: 24px;
}
.formbox4{
  width: 600px;
  height: 400px;
  margin-top: 2rem;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #FFFFFF;
border-radius: 24px;
padding: 1.5rem 5rem;
}
.formbox5{
  width: 550px;
  height: 250px;
  margin-top: 2rem;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #FFFFFF;
border-radius: 24px;
padding: 1.5rem 5rem;
}
.formbox6{
  width: 600px;
  height: 550px;
  margin-top: 2rem;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #FFFFFF;
border-radius: 24px;
padding: 1.5rem 5rem;
}
.formbox7{
  width: 550px;
  height: 300px;
  margin-top: 2rem;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #FFFFFF;
border-radius: 24px;
padding: 1.5rem 5rem;
}
.formbox8{
  width: 300px;
  height: 270px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #FFFFFF;
border-radius: 24px;
padding: 0.5rem 3rem;
}
.formbox8a{
  width: 400px;
  height: 320px;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #FFFFFF;
border-radius: 24px;
padding: 1rem 2rem;
}
.formbox9{
  width: 30%;
  height: 500px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #fcf8f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  gap: 4px;
}
.formbox10{
  width: 69%;
  height: 500px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* background: #FFFFFF; */
  border-radius: 10px;
}
.sliderheader{
  display: flex;
  width: 100%;
  height: 100px;
  padding: 5px;
  overflow-x: scroll;
  gap: 12px;
}
.productlistbody{
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 10px;
  padding: 10px 10px 0;
}
.sliderheaderitem{
  min-width: 15%;
  background: #FFFFFF;
  box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 8px 0 0;
}
.productlistitem{
  min-width: 20%;
  height: 150px;
  background: #FFFFFF;
  box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 8px 0 0;
}
.craftitem{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 80px;
  background: #FFFFFF;
  box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 8px 0 0;
}
.sliderheaderinneritem{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.productlistinneritem{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.displayflex{
  display: flex;
  align-items: center;
  justify-content: center;
}
.borderbuttom{
  border-bottom: 5px solid #FD2254;
  border-radius: 10px;
  margin: 6px auto 0;
}
.singlecard {
  display: flex;
  flex-direction: column;
  font-size: 13px;
}
.forgap{
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.innersinglecard{
    margin: 7px auto;
    display: flex;
    /* flex-direction: column; */
    gap: 7px;
}
.imgsize{
  width: 60px;
  height: 60px;
}
.iconback{
  width: 40px;
  height: 35px;
  background: rgba(255, 242, 233, 0.8);
  opacity: 0.65;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.iconback2 {
  width: 40px;
  height: 35px;
  opacity: 0.65;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background: #EAF9FF;
}
.iconback3 {
  width: 40px;
  height: 35px;
  opacity: 0.65;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background: #EDE8FF;
}
.iconback4 {
  width: 40px;
  height: 35px;
  opacity: 0.65;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background: #FFEBEF;
}

.singlecardhead{
  display: flex;
  gap: 100px;
}
.singlecardhead2{
  display: flex;
  margin-top: 1rem;
  background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 25px;
padding: 30px;
height: 100px;
}
.singlecardhead3{
  display: flex;
  background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 25px;
padding: 20px;
    height: 130px;
    width: 500px;
}
.singlecardhead4{
  display: flex;
    align-items: center;
    margin-top: 1rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 25px;
    padding: 10px;
    height: 60px;
}
.singlecardhead5{
  display: flex;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 25px;
    padding: 10px;
    height: 90px;
    width: 150px;
    font-size: 13px;
}
.combine{
  display: flex;
  gap: 1rem;
}
.combine2{
  display: flex;
  gap: 5px;
}
.editcustomer{
  width: 70%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}

.loadingText{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  gap: 5px;
}

@media screen and (max-width: 1200px) {
  .btn {
    margin-left: 83%;
  }

  .changePass {
    font-size: 28px;
  }

  .sign-up-container input {
    width: 150px;
    padding-left: 5px;
    font-size: 20px;
  }
}

@media screen and (max-width: 800px) {
  .btn {
    margin-left: 78%;
  }

  .changePass {
    font-size: 22px;
  }

  .sign-up-container input {
    width: 110px;
    padding-left: 3px;
    font-size: 15px;
  }

  tr {
    font-size: 20px;
  }

  td {
    padding: 0;
  }
}

@media screen and (max-width: 600px) {
  .logoimage{
    display: none;
  }
  .vipslogo{
    left: 0.3rem;
    top: 1.75rem;
  }
  .checkin{
    width: 100%;
  }
  .checkinform{
    margin: 0;
  }
  .checkInSearch{
    width: 150px;
  }
  .checkInSearch4{
    width: 90%;
    margin-top: 10px;
  }
  .checkinwidth{
    width: 100%;
  }
  .combine2{
    margin-bottom: 1rem;
  }
  .searchbox2{
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 97%;
    margin-top: 3rem;
    gap: 1rem;
  }
  .searchbox3{
    flex-direction: column;
    gap: 1rem;
  }
  .formbox3{
    width: 92%;
  }
  .formbox9{
    width: 100%;
    height: auto;
  }
  .formbox10{
    width: 96%;
    height: auto;
  }
  .innersinglecard{
    margin: 7px auto;
  }
  .combine{
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .singlecardhead2{
    width: 92%;
  }
  .singlecardhead3{
    width: 92%;
  }
  .checkInSearch2{
    width: 175px;
  }
  .addcustomerheader{
    margin-top: 5rem;
    font-size: 30px;
  }
  .formbox4{
    width: 320px;
    height: 400px;
    margin-top: 1rem;
    padding: 1.5rem 1rem;
  }
  .formbox7{
    width: 320px;
    height: 300px;
    margin: 7rem auto;
    padding: 1.5rem 1rem;
  }
  .formbox8{
    width: 320px;
    height: 300px;
    margin-top: 1rem;
    padding: 1.5rem 1rem;
  }
  .setup{
    overflow: scroll;
  }
  .editcustomer{
    width: auto;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    margin: 0 auto;
  }
  .formbox6{
    width: 320px;
    height:540px;
    margin-top: 1rem;
    padding: 1.5rem 1rem;
  }






  .btn {
    margin-left: 75%;
  }

  .changePass {
    font-size: 20px;
  }

  .sign-up-container input {
    width: 80px;
    font-size: 15px;
  }

  tr {
    font-size: 15px;
  }

  .text-center {
    text-align: start;
  }
  label,
  button {
    /* margin-top: 0.5rem; */
    font-size: 15px;
    /* width: 90px; */
  }
  .singlecardhead{
    gap: 50px;
  }
}

@media screen and (max-width: 400px) {
  .btn {
    margin-left: 70%;
  }

  .sign-up-container input {
    width: 70px;
    font-size: 13px;
  }

  th {
    padding: 0;
  }

  td {
    text-align: start;
  }
}

@media screen and (max-width: 340px) {
  .btn {
    margin-left: 65%;
  }
}

.diamond{
  background-image: linear-gradient(to right, #d3dee7,#82a3dd,#d3dee7);
}
.gold{
  background-image: linear-gradient(to right,#ecd088,#dbb65b,#ebcc7e);
  
}
.silver{
  background-image: linear-gradient(to right,#cad0d6,#aab3bb,#cad0d6)
}