@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mouse+Memoirs&family=Roboto:wght@300&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@400;700&display=swap');

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/*   color variables */
:root {
    --clr-primary: #81d4fa;
    --clr-primary-light: #e1f5fe;
    --clr-primary-dark: #4fc3f7;
    --clr-gray100: #f9fbff;
    --clr-gray150: #f4f6fb;
    --clr-gray200: #eef1f6;
    --clr-gray300: #e1e5ee;
    --clr-gray400: #767b91;
    --clr-gray500: #4f546c;
    --clr-gray600: #2a324b;
    --clr-gray700: #161d34;
    --clr-pending: #fff0c2;
    --clr-pending-font: #a68b00;
    --clr-unpaid: #ffcdd2;
    --clr-unpaid-font: #c62828;
    --clr-paid: #c8e6c9;
    --clr-paid-font: #388e3c;
    --clr-link: #2962ff;

    /*   border radius */
    --radius: 0.2rem;
}

html {
    /* font-family: 'Roboto', sans-serif; */
    font-size: 22px;
    color: #fff;
}

body {
    min-height: 100vh;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    /* background-color: dodgerblue; */
}

.allcustomerheader {
    display: flex;
    justify-content: space-between;
    margin: 2rem 1rem 1rem 1rem;
    align-items: center;
}

.table {
    background-color: #fff;
    font-family: 'Mukta', sans-serif;
    /* border: 1px solid #ccc; */
    border-collapse: collapse;
    /* border-radius: 6px; */
    /* width: 100%; */
    text-align: left;
    /* margin-bottom: 2rem;
    border-radius: 20px 20px 0 0; */
    color: #2B3B47;
}

.table2 {
    margin: 0 auto;
}

.outertable {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

thead {
    box-shadow: 0 5px 10px var(--clr-gray300);
}

tr {
    border-bottom: 1px solid #e7e7e7;
    font-size: 25px;
}

td {
    font-weight: 500;
    padding: 10px 20px;
    text-align: center;
    /* min-width: 140px; */
}

th {
    padding: 10px 0;
    text-align: center;
    /* font-family: Lato-Bold; */
    font-size: 18px;
    color: #fff;
    line-height: 1.4;
    letter-spacing: 0.1rem;
    background: rgba(7, 29, 84, 0.9);
    min-width: 90px;
    font-weight: unset;
}

.block {
    display: block;
}

.none {
    display: none;
}

.section {
    width: 100%;
    max-width: 420px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.4);
}

.section2 {
    color: black;
    width: 100%;
    max-width: 420px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
}

.section3 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.4);
}

/* form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding-bottom: 1rem;
} */
.formcontainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.formcontainer2 {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions>svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}


/* chooseplan */
.container {
    width: 100%;
    background-color: azure;
    color: black;
    min-height: 100vh;
}

.container1 {
    width: 100%;
    background-color: azure;
    color: black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    margin-top: 1rem;
}

.heading {
    text-align: center;
    color: red;
    text-decoration: underline;
    margin-bottom: 10px;
}

.secondheading {
    margin-left: 1%;
}

h3 span {
    color: gray;
}

.zero {
    margin: 0;
    padding: 0;
    color: black;
}

.link {
    text-decoration: none;
}

.first {
    display: flex;
    flex-direction: column;
}


.lds-facebook {
    display: inline-block;
    position: relative;
    width: 1000px;
    height: 1800px;
    margin-left: 50%;
    margin-top: 20vh;
}

.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: green;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}

@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 64px;
    }

    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}


@media screen and (max-width: 600px) {
    .formcontainer {
        margin-top: 1rem;
    }

    .allcustomerheader {
        flex-direction: column;
        margin: 1rem auto 1rem;
    }

    .outertable {
        margin-top: 1.2rem;
    }

    .allcustomerinput {
        width: 250px;
        box-sizing: border-box;
        border: 1px solid white;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        border: none;
    }
    
}